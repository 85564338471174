@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

footer {
   
    height: 20vh;
    background-color: #00252a ;
    padding: 30px;
}
.foot {
    color: white;
    /* display: flex;
    flex-direction: column; */
}
.footer-container {
    padding: 0 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mike {
    display: inline;
    margin-left: 5px;
    color: azure;
    font-style: italic;
}

.footer-contact {
    color: white;
    font-family: 'Open Sans', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.footer-contact p {
    margin: 0;
}

.mind {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 20;
}