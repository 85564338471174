@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
form {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 400px;
    padding-bottom: 20px;
   

  
}
input[type="email"] {
    display: block;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    
}
textarea {
    width: 100%;
    height: 200px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
    margin-bottom: 5px;
    width: 400px;
  }
  
  .button {
    display: block;
    margin-bottom: 10px;
    padding: 12px 20px;
    background-color: #00B9D3;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 600;
     box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
}
.h3-contact {
    display: flex;
     justify-content: center;
    align-items: center;
    color: grey;
    border-bottom: #00B9D3 3px solid;
    font-size: 30px;
    line-height: 38px;
    width: 210px;
    margin: 0 auto;
    padding: 10px;
}
label {
    font-size: 20px;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    color: grey;
    margin-bottom: 20px;
}
.contact-img {
    height: 400px;
    max-width: 500px;
    width: 100%;
    
    object-fit: cover;
   
    border-radius: 8px;
}
.flex-contact {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
}

.conatact-container {
    padding: 80px 100px;
    /* justify-content: center;
    align-items: center; */
}