@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

.skills-container {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
    margin-bottom: 0;
   
}
.mini-container {
    padding: 70px 100px;
}
.stack {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(4,1fr);
}

.pStark {
   text-align: center;
   margin: 0 auto;
   margin-bottom: 40px;
   letter-spacing: 0.35538em;
   font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    width: 200px;
    color: white;
    border-bottom: 3px white solid;
    padding-bottom: 8px;
} 
