@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
.about--container {
    
    padding: 70px 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.about {
    border-radius: 8px;
    border: 2px solid#00B9D3;
    max-width: 50%;
    margin-right: 40px;
    height: 400px;  
    /* width: 100%; */
}
.right--side {
    width: 40%;

    
}
.h3{
   font-weight: 700;
   font-size: 25px;
   letter-spacing: 4px;
}