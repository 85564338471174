@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
.profile-container {
    
   background: rgb(243, 241, 241);

}
.mini-container {
    padding: 100px 100px;
}
.profile--image {
    height: 200px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
} 
.items {
    width: 500px;
    height: 500px;
    border: 1px solid #00252a;
;
    padding: 20px;
    border-radius: 8px;
   
}
.title {
  font-weight: 700;
  color:   #00252a;
  font-size: 26px;
  margin-bottom: 0;
  font-family: 'Open Sans', sans-serif;
}

.description{
    font-weight: 400;
    color:  black;
    font-size: 20px;
    margin-bottom: 0;
    font-style: normal;
    font-family: 'Open Sans', sans-serif;

}

.brief-description {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 15px; 
}

.view-project {
   border: 1.5px solid #00252a;
   width: 6.9em;
   padding: 5px 16px;
   font-weight: 500;
   font-family: 'Open Sans', sans-serif;

}
.card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    justify-content: space-around;
}


