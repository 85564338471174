@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');


body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}
.nav-container {
    margin: 0;
    height: 69vh;
    background: linear-gradient(102deg, #00B9D3 50%, #00252a 50%);
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

.menu-container {
    display: flex;
    justify-content: space-between;
    padding: 0 200px;
    margin: 0; 
    position: relative;
    
   
}

.nav-list {
    display: flex;
    list-style: none;
    text-decoration: none;
    justify-content: space-around;
}
.nav-list li {
    margin-right: 20px;
    list-style: none;
    text-decoration: none;
   
}
.contact {
    background: #FFFFFF;
    border: solid #FFFFFF;
    border-radius: 30px;
    color: #000000;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 5px;
   
}
.hero-container {
    display: flex;
    justify-content: space-between;
    padding: 0 200px;
    margin: 0; 
    position: initial;
   height: inherit;
}
.profileImage {
    object-fit: contain;
    height: 61vmin;
}
.introduction {
    display: flex;
    flex-direction: column;
    margin-top: 120px;
}
.introduction > :nth-child(2){
    color: white;
}
.name {
    margin-top: 4rem;
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;   
   
}
.developer {
    margin-bottom: 60px;
    margin-top: 0;
    font-style: normal;
    font-size: 1rem;
   
    color: #3a3a3a ;
}
.fa-maxcdn {
    font-size: 40px;
    margin-top: 20px;
}
.michael{
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-right: 31px;
    padding: 7px;
    border-radius: 3px;
}
.firstword {
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 47px;
    margin-bottom: 0;
}



